<template>
  <section>
    <loading class="col-lg-12 p-0" v-if="estadoloading" />
    <div class="row p-0 m-0" v-else>
      <div class="col-lg-3 col-xl-3 col-md-3 bg-lateral d-none d-lg-block">
        <div class="mx-auto text-center py-3">
          <img
            src="@/assets/img/logo-holistica.svg"
            alt=""
            srcset=""
            class=""
            height="50"
          />
        </div>
        <div class="mt-5 img-responsive" v-if="falicitadores.taller">
          <h5 class="text-center border-taller pb-3 color-taller">
            {{ falicitadores.taller.nombre_taller }}
          </h5>
          <p class="text-center color-descripcion">
            Nombre del entrenamiento o capacitación
          </p>
        </div>
        <div class="mt-5 img-responsive" v-if="falicitadores.taller">
          <h5 class="text-center border-taller pb-3 color-taller">
            {{ falicitadores.taller.empresa }}
          </h5>
          <p class="text-center color-descripcion">Nombre de la empresa</p>
        </div>
        <div class="mt-5 img-responsive" v-if="falicitadores.taller">
          <h5 class="text-center border-taller pb-3 color-taller">
            {{ format_date(falicitadores.taller.fecha) }}
          </h5>
          <p class="text-center color-descripcion">Fecha</p>
        </div>
        <div class="mt-5 img-responsive" v-if="falicitadores.taller">
          <h5 class="text-center border-taller pb-3 color-taller">
            {{ falicitadores.taller.ciudad }}
          </h5>
          <p class="text-center color-descripcion">Lugar</p>
        </div>
        <div class="mx-auto text-center mt-5 img-responsive">
          <img
            src="@/assets/img/logo-cerforc.svg"
            alt=""
            srcset=""
            height="60"
          />
        </div>
      </div>
      <div class="col-lg-9 col-xl-9 col-md-12 content-calificacion">
        <div class="pt-4">
          <h5 class="color-taller text-center">
            Evaluación de servicios FORCOM
          </h5>
          <p class="color-descripcion text-center">
            Código: FOR-SER-00{{ falicitadores.not_id }} Versión 02
          </p>
        </div>
        <div class="row m-0 p-0 mt-5">
          <div class="col-lg-8 col-xl-8 col-md-12 d-none d-md-block">
            <div class="row m-0 p-0">
              <div class="col-lg-12">
                <h5 class="color-taller text-center m-0 p-0">
                  Marque la casilla correspondiente según su experiencia.
                </h5>
              </div>
              <div class="col-lg-12 col-xl-12 col-md-12">
                <div class="row m-0 p-0 mt-3">
                  <div class="col-lg-3 col-md-3 color-descripcion text-center">
                    5 Excelente
                  </div>
                  <div
                    class="col-lg-3 col-xl-3 col-md-2 color-descripcion text-center"
                  >
                    4 Muy bueno
                  </div>
                  <div
                    class="col-lg-2 col-xl-2 col-md-2 color-descripcion text-center"
                  >
                    3 Bueno
                  </div>
                  <div
                    class="col-lg-2 col-xl-2 col-md-2 color-descripcion text-center"
                  >
                    2 Regular
                  </div>
                  <div
                    class="col-lg-2 col-xl-2 col-md-2 color-descripcion text-center"
                  >
                    1 Malo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-xl-4 col-md-12 espaciado">
            <div class="row m-0 p-0">
              <div
                class="col-lg-12 text-center"
                v-if="falicitadores.not_id != undefined"
              >
                <img
                  src="@/assets/img/uploadFile.jpg"
                  alt=""
                  srcset=""
                  class="border-imagen mt-lg-n5"
                  width="110"
                  height="100"
                  v-if="
                    falicitadores.not_id != undefined &&
                    !falicitadores.formadores[posicion].foto
                  "
                />
                <img
                  :src="foto + falicitadores.formadores[posicion].foto"
                  alt=""
                  srcset=""
                  class="border-imagen mt-lg-n5"
                  width="110"
                  height="100"
                  v-else
                />
                <p
                  class="pt-2 m-0 nombre-formador"
                  v-if="falicitadores.not_id != undefined"
                >
                  {{ falicitadores.formadores[posicion].fullname }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mt-2">
            <!-- Primera pregunta -->
            <div class="row m-0 p-0">
              <div class="col-lg-8 col-xl-8 col-md-8 col-12">
                <h5 class="color-taller m-0 p-0">
                  Facilitador o conferencista
                </h5>
              </div>
              <div class="col-lg-4 col-xl-4 col-md-4 d-none d-md-block">
                <div class="justify-content-center m-0 p-0 row">
                  <div class="col-1 p-0 m-0 color-descripcion">1</div>
                  <div class="col-1 p-0 m-0 color-descripcion">2</div>
                  <div class="col-1 p-0 m-0 color-descripcion">3</div>
                  <div class="col-1 p-0 m-0 color-descripcion">4</div>
                  <div class="col-1 p-0 m-0 color-descripcion">5</div>
                </div>
              </div>
            </div>
            <!-- preguntas -->
            <div class="row m-0 p-0 mt-2">
              <div
                class="align-items-center pregunta bg-intercalado col-lg-8 col-xl-8 col-md-8 col-12 d-flex"
                style="height: 35px"
              >
                <p class="m-0 p-0 pl-2" style="font-size: 1.2rem">
                  Cumplimiento de los objetivos
                </p>
              </div>
              <div
                class="col-12 bg-intercalado pt-1 d-block d-md-none d-lg-none d-xl-none"
              >
                <div
                  class="m-0 p-0 row"
                  style="padding-left: 1.2rem !important"
                >
                  <div class="col-1 p-0 m-0 color-descripcion">1</div>
                  <div class="col-1 p-0 m-0 color-descripcion">2</div>
                  <div class="col-1 p-0 m-0 color-descripcion">3</div>
                  <div class="col-1 p-0 m-0 color-descripcion">4</div>
                  <div class="col-1 p-0 m-0 color-descripcion">5</div>
                </div>
              </div>
              <div
                class="col-lg-4 col-xl-4 col-md-4 col-12 bg-intercalado"
                v-if="calificaciones[0] != undefined"
              >
                <div
                  class="align-items-center justify-content-center respuesta m-0 p-0 row"
                  style="padding-top: 0.3rem !important"
                >
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline1"
                        name="customRadioInline"
                        class="custom-control-input"
                        value="1"
                        v-model="calificaciones[posicion].calificacion_1"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline1"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline2"
                        name="customRadioInline"
                        class="custom-control-input"
                        value="2"
                        v-model="calificaciones[posicion].calificacion_1"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline2"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline3"
                        name="customRadioInline"
                        class="custom-control-input"
                        value="3"
                        v-model="calificaciones[posicion].calificacion_1"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline3"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline4"
                        name="customRadioInline"
                        class="custom-control-input"
                        value="4"
                        v-model="calificaciones[posicion].calificacion_1"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline4"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline5"
                        name="customRadioInline"
                        class="custom-control-input"
                        value="5"
                        v-model="calificaciones[posicion].calificacion_1"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline5"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
              <div
                class="align-items-center pregunta col-lg-8 d-flex col-xl-8 col-md-8"
                style="height: 35px"
              >
                <p class="m-0 p-0 pl-2" style="font-size: 1.2rem">
                  Conocimiento y dominio del tema
                </p>
              </div>
              <div class="col-12 d-block pt-1 d-md-none d-lg-none d-xl-none">
                <div
                  class="m-0 p-0 row"
                  style="padding-left: 1.2rem !important"
                >
                  <div class="col-1 p-0 m-0 color-descripcion">1</div>
                  <div class="col-1 p-0 m-0 color-descripcion">2</div>
                  <div class="col-1 p-0 m-0 color-descripcion">3</div>
                  <div class="col-1 p-0 m-0 color-descripcion">4</div>
                  <div class="col-1 p-0 m-0 color-descripcion">5</div>
                </div>
              </div>
              <div
                class="col-lg-4 col-xl-4 col-md-4"
                v-if="calificaciones[0] != undefined"
              >
                <div
                  class="align-items-center justify-content-center respuesta m-0 p-0 row"
                  style="padding-top: 0.3rem !important"
                >
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline6"
                        name="customRadioInline1"
                        class="custom-control-input"
                        value="1"
                        v-model="calificaciones[posicion].calificacion_2"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline6"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline7"
                        name="customRadioInline1"
                        class="custom-control-input"
                        value="2"
                        v-model="calificaciones[posicion].calificacion_2"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline7"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline8"
                        name="customRadioInline1"
                        class="custom-control-input"
                        value="3"
                        v-model="calificaciones[posicion].calificacion_2"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline8"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline9"
                        name="customRadioInline1"
                        class="custom-control-input"
                        value="4"
                        v-model="calificaciones[posicion].calificacion_2"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline9"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline10"
                        name="customRadioInline1"
                        class="custom-control-input"
                        value="5"
                        v-model="calificaciones[posicion].calificacion_2"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline10"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
              <div
                class="align-items-center pregunta bg-intercalado col-lg-8 d-flex col-xl-8 col-md-8"
                style="height: 35px"
              >
                <p class="m-0 p-0 pl-2" style="font-size: 1.2rem">
                  Manejo del grupo
                </p>
              </div>
              <div
                class="col-12 pt-1 bg-intercalado d-block d-md-none d-lg-none d-xl-none"
              >
                <div
                  class="m-0 p-0 row"
                  style="padding-left: 1.2rem !important"
                >
                  <div class="col-1 p-0 m-0 color-descripcion">1</div>
                  <div class="col-1 p-0 m-0 color-descripcion">2</div>
                  <div class="col-1 p-0 m-0 color-descripcion">3</div>
                  <div class="col-1 p-0 m-0 color-descripcion">4</div>
                  <div class="col-1 p-0 m-0 color-descripcion">5</div>
                </div>
              </div>
              <div
                class="col-lg-4 col-xl-4 col-md-4 bg-intercalado"
                v-if="calificaciones[0] != undefined"
              >
                <div
                  class="align-items-center justify-content-center respuesta m-0 p-0 row"
                  style="padding-top: 0.3rem !important"
                >
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline11"
                        name="customRadioInline2"
                        class="custom-control-input"
                        value="1"
                        v-model="calificaciones[posicion].calificacion_3"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline11"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline12"
                        name="customRadioInline2"
                        class="custom-control-input"
                        value="2"
                        v-model="calificaciones[posicion].calificacion_3"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline12"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline13"
                        name="customRadioInline2"
                        class="custom-control-input"
                        value="3"
                        v-model="calificaciones[posicion].calificacion_3"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline13"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline14"
                        name="customRadioInline2"
                        class="custom-control-input"
                        value="1"
                        v-model="calificaciones[posicion].calificacion_3"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline14"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline15"
                        name="customRadioInline2"
                        class="custom-control-input"
                        value="5"
                        v-model="calificaciones[posicion].calificacion_3"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline15"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
              <div
                class="align-items-center pregunta col-lg-8 d-flex col-xl-8 col-md-8"
                style="height: 35px"
              >
                <p class="m-0 p-0 pl-2" style="font-size: 1.2rem">
                  Claridad y orden en las presentaciones
                </p>
              </div>
              <div class="col-12 pt-1 d-block d-md-none d-lg-none d-xl-none">
                <div
                  class="m-0 p-0 row"
                  style="padding-left: 1.2rem !important"
                >
                  <div class="col-1 p-0 m-0 color-descripcion">1</div>
                  <div class="col-1 p-0 m-0 color-descripcion">2</div>
                  <div class="col-1 p-0 m-0 color-descripcion">3</div>
                  <div class="col-1 p-0 m-0 color-descripcion">4</div>
                  <div class="col-1 p-0 m-0 color-descripcion">5</div>
                </div>
              </div>
              <div
                class="col-lg-4 col-xl-4 col-md-4"
                v-if="calificaciones[0] != undefined"
              >
                <div
                  class="align-items-center justify-content-center respuesta m-0 p-0 row"
                  style="padding-top: 0.3rem !important"
                >
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline16"
                        name="customRadioInline3"
                        class="custom-control-input"
                        value="1"
                        v-model="calificaciones[posicion].calificacion_4"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline16"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline17"
                        name="customRadioInline3"
                        class="custom-control-input"
                        value="2"
                        v-model="calificaciones[posicion].calificacion_4"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline17"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline18"
                        name="customRadioInline3"
                        class="custom-control-input"
                        value="3"
                        v-model="calificaciones[posicion].calificacion_4"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline18"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline19"
                        name="customRadioInline3"
                        class="custom-control-input"
                        value="4"
                        v-model="calificaciones[posicion].calificacion_4"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline19"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline20"
                        name="customRadioInline3"
                        class="custom-control-input"
                        value="5"
                        v-model="calificaciones[posicion].calificacion_4"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline20"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
              <div
                class="align-items-center pregunta bg-intercalado col-lg-8 d-flex col-xl-8 col-md-8"
                style="height: 35px"
              >
                <p class="m-0 p-0 pl-2" style="font-size: 1.2rem">
                  Cumplimiento de los temas programados
                </p>
              </div>
              <div
                class="col-12 pt-1 bg-intercalado d-block d-md-none d-lg-none d-xl-none"
              >
                <div
                  class="m-0 p-0 row"
                  style="padding-left: 1.2rem !important"
                >
                  <div class="col-1 p-0 m-0 color-descripcion">1</div>
                  <div class="col-1 p-0 m-0 color-descripcion">2</div>
                  <div class="col-1 p-0 m-0 color-descripcion">3</div>
                  <div class="col-1 p-0 m-0 color-descripcion">4</div>
                  <div class="col-1 p-0 m-0 color-descripcion">5</div>
                </div>
              </div>
              <div
                class="col-lg-4 col-xl-4 col-md-4 bg-intercalado"
                v-if="calificaciones[0] != undefined"
              >
                <div
                  class="align-items-center justify-content-center respuesta m-0 p-0 row"
                  style="padding-top: 0.3rem !important"
                >
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline21"
                        name="customRadioInline4"
                        class="custom-control-input"
                        value="1"
                        v-model="calificaciones[posicion].calificacion_5"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline21"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline22"
                        name="customRadioInline4"
                        class="custom-control-input"
                        value="2"
                        v-model="calificaciones[posicion].calificacion_5"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline22"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline23"
                        name="customRadioInline4"
                        class="custom-control-input"
                        value="3"
                        v-model="calificaciones[posicion].calificacion_5"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline23"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline24"
                        name="customRadioInline4"
                        class="custom-control-input"
                        value="4"
                        v-model="calificaciones[posicion].calificacion_5"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline24"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline25"
                        name="customRadioInline4"
                        class="custom-control-input"
                        value="5"
                        v-model="calificaciones[posicion].calificacion_5"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline25"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
              <div
                class="align-items-center pregunta col-lg-8 d-flex col-xl-8 col-md-8"
                style="height: 35px"
              >
                <p class="m-0 p-0 pl-2" style="font-size: 1.2rem">
                  Puntualidad
                </p>
              </div>
              <div class="col-12 pt-1 d-block d-md-none d-lg-none d-xl-none">
                <div
                  class="m-0 p-0 row"
                  style="padding-left: 1.2rem !important"
                >
                  <div class="col-1 p-0 m-0 color-descripcion">1</div>
                  <div class="col-1 p-0 m-0 color-descripcion">2</div>
                  <div class="col-1 p-0 m-0 color-descripcion">3</div>
                  <div class="col-1 p-0 m-0 color-descripcion">4</div>
                  <div class="col-1 p-0 m-0 color-descripcion">5</div>
                </div>
              </div>
              <div
                class="col-lg-4 col-xl-4 col-md-4"
                v-if="calificaciones[0] != undefined"
              >
                <div
                  class="align-items-center justify-content-center respuesta m-0 p-0 row"
                  style="padding-top: 0.3rem !important"
                >
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline26"
                        name="customRadioInline5"
                        class="custom-control-input"
                        value="1"
                        v-model="calificaciones[posicion].calificacion_6"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline26"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline27"
                        name="customRadioInline5"
                        class="custom-control-input"
                        value="2"
                        v-model="calificaciones[posicion].calificacion_6"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline27"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline28"
                        name="customRadioInline5"
                        class="custom-control-input"
                        value="3"
                        v-model="calificaciones[posicion].calificacion_6"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline28"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline29"
                        name="customRadioInline5"
                        class="custom-control-input"
                        value="4"
                        v-model="calificaciones[posicion].calificacion_6"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline29"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline30"
                        name="customRadioInline5"
                        class="custom-control-input"
                        value="5"
                        v-model="calificaciones[posicion].calificacion_6"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline30"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
              <div
                class="align-items-center pregunta bg-intercalado col-lg-8 d-flex col-xl-8 col-md-8"
                style="height: 35px"
              >
                <p class="m-0 p-0 pl-2" style="font-size: 1.2rem">
                  Presentación personal
                </p>
              </div>
              <div
                class="col-12 pt-1 bg-intercalado d-block d-md-none d-lg-none d-xl-none"
              >
                <div
                  class="m-0 p-0 row"
                  style="padding-left: 1.2rem !important"
                >
                  <div class="col-1 p-0 m-0 color-descripcion">1</div>
                  <div class="col-1 p-0 m-0 color-descripcion">2</div>
                  <div class="col-1 p-0 m-0 color-descripcion">3</div>
                  <div class="col-1 p-0 m-0 color-descripcion">4</div>
                  <div class="col-1 p-0 m-0 color-descripcion">5</div>
                </div>
              </div>
              <div
                class="col-lg-4 col-xl-4 col-md-4 bg-intercalado"
                v-if="calificaciones[0] != undefined"
              >
                <div
                  class="align-items-center justify-content-center respuesta m-0 p-0 row"
                  style="padding-top: 0.3rem !important"
                >
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline31"
                        name="customRadioInline6"
                        class="custom-control-input"
                        value="1"
                        v-model="calificaciones[posicion].calificacion_7"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline31"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline32"
                        name="customRadioInline6"
                        class="custom-control-input"
                        value="2"
                        v-model="calificaciones[posicion].calificacion_7"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline32"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline33"
                        name="customRadioInline6"
                        class="custom-control-input"
                        value="3"
                        v-model="calificaciones[posicion].calificacion_7"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline33"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline34"
                        name="customRadioInline6"
                        class="custom-control-input"
                        value="4"
                        v-model="calificaciones[posicion].calificacion_7"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline34"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline35"
                        name="customRadioInline6"
                        class="custom-control-input"
                        value="5"
                        v-model="calificaciones[posicion].calificacion_7"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline35"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
              <div
                class="align-items-center pregunta col-lg-8 d-flex alto-personalizado col-xl-8 col-md-8"
              >
                <p class="m-0 p-0 pl-2" style="font-size: 1.2rem">
                  Facilidad para captar el nivel de comprensión e interés de los
                  participantes
                </p>
              </div>
              <div class="col-12 pt-1 d-block d-md-none d-lg-none d-xl-none">
                <div
                  class="m-0 p-0 row"
                  style="padding-left: 1.2rem !important"
                >
                  <div class="col-1 p-0 m-0 color-descripcion">1</div>
                  <div class="col-1 p-0 m-0 color-descripcion">2</div>
                  <div class="col-1 p-0 m-0 color-descripcion">3</div>
                  <div class="col-1 p-0 m-0 color-descripcion">4</div>
                  <div class="col-1 p-0 m-0 color-descripcion">5</div>
                </div>
              </div>
              <div
                class="col-lg-4 col-xl-4 col-md-4"
                v-if="calificaciones[0] != undefined"
              >
                <div
                  class="align-items-center justify-content-center respuesta m-0 p-0 row alineacion-responsive"
                >
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline36"
                        name="customRadioInline7"
                        class="custom-control-input"
                        value="1"
                        v-model="calificaciones[posicion].calificacion_8"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline36"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline37"
                        name="customRadioInline7"
                        class="custom-control-input"
                        value="2"
                        v-model="calificaciones[posicion].calificacion_8"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline37"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline38"
                        name="customRadioInline7"
                        class="custom-control-input"
                        value="3"
                        v-model="calificaciones[posicion].calificacion_8"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline38"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline39"
                        name="customRadioInline7"
                        class="custom-control-input"
                        value="4"
                        v-model="calificaciones[posicion].calificacion_8"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline39"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline40"
                        name="customRadioInline7"
                        class="custom-control-input"
                        value="5"
                        v-model="calificaciones[posicion].calificacion_8"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline40"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
              <div
                class="align-items-center bg-intercalado col-lg-8 d-flex alto-personalizado col-xl-8 col-md-8"
              >
                <p class="m-0 p-0 pl-2" style="font-size: 1.2rem">
                  Capacidad de fomentar procesos de análisis y síntesis en los
                  participantes
                </p>
              </div>
              <div
                class="col-12 pt-1 bg-intercalado d-block d-md-none d-lg-none d-xl-none"
              >
                <div
                  class="m-0 p-0 row"
                  style="padding-left: 1.2rem !important"
                >
                  <div class="col-1 p-0 m-0 color-descripcion">1</div>
                  <div class="col-1 p-0 m-0 color-descripcion">2</div>
                  <div class="col-1 p-0 m-0 color-descripcion">3</div>
                  <div class="col-1 p-0 m-0 color-descripcion">4</div>
                  <div class="col-1 p-0 m-0 color-descripcion">5</div>
                </div>
              </div>
              <div
                class="col-lg-4 col-xl-4 col-md-4 bg-intercalado"
                v-if="calificaciones[0] != undefined"
              >
                <div
                  class="align-items-center justify-content-center respuesta m-0 p-0 row alineacion-responsive"
                >
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline41"
                        name="customRadioInline8"
                        class="custom-control-input"
                        value="1"
                        v-model="calificaciones[posicion].calificacion_9"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline41"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline42"
                        name="customRadioInline8"
                        class="custom-control-input"
                        value="2"
                        v-model="calificaciones[posicion].calificacion_9"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline42"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline43"
                        name="customRadioInline8"
                        class="custom-control-input"
                        value="3"
                        v-model="calificaciones[posicion].calificacion_9"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline43"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline44"
                        name="customRadioInline8"
                        class="custom-control-input"
                        value="4"
                        v-model="calificaciones[posicion].calificacion_9"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline44"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline45"
                        name="customRadioInline8"
                        class="custom-control-input"
                        value="5"
                        v-model="calificaciones[posicion].calificacion_9"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline45"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
              <div
                class="align-items-center col-lg-8 d-flex alto-personalizado col-xl-8 col-md-8"
              >
                <p class="m-0 p-0 pl-2" style="font-size: 1.2rem">
                  Ayudas audiovisuales utilizadas (monitor, video beam, entre
                  otros)
                </p>
              </div>
              <div class="col-12 pt-1 d-block d-md-none d-lg-none d-xl-none">
                <div
                  class="m-0 p-0 row"
                  style="padding-left: 1.2rem !important"
                >
                  <div class="col-1 p-0 m-0 color-descripcion">1</div>
                  <div class="col-1 p-0 m-0 color-descripcion">2</div>
                  <div class="col-1 p-0 m-0 color-descripcion">3</div>
                  <div class="col-1 p-0 m-0 color-descripcion">4</div>
                  <div class="col-1 p-0 m-0 color-descripcion">5</div>
                </div>
              </div>
              <div
                class="col-lg-4 col-xl-4 col-md-4"
                v-if="calificaciones[0] != undefined"
              >
                <div
                  class="align-items-center justify-content-center respuesta m-0 p-0 row alineacion-responsive"
                >
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline46"
                        name="customRadioInline9"
                        class="custom-control-input"
                        value="1"
                        v-model="calificaciones[posicion].calificacion_10"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline46"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline47"
                        name="customRadioInline9"
                        class="custom-control-input"
                        value="2"
                        v-model="calificaciones[posicion].calificacion_10"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline47"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline48"
                        name="customRadioInline9"
                        class="custom-control-input"
                        value="3"
                        v-model="calificaciones[posicion].calificacion_10"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline48"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline49"
                        name="customRadioInline9"
                        class="custom-control-input"
                        value="4"
                        v-model="calificaciones[posicion].calificacion_10"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline49"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline50"
                        name="customRadioInline9"
                        class="custom-control-input"
                        value="5"
                        v-model="calificaciones[posicion].calificacion_10"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline50"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Segunda pregunta -->
            <div class="row m-0 p-0 mt-3">
              <div class="col-lg-8 col-xl-8 col-md-12">
                <h5 class="color-taller m-0 p-0">Aplicabilidad del tema</h5>
              </div>
            </div>
            <!-- preguntas -->
            <div class="row m-0 p-0 mt-2">
              <div
                class="align-items-center bg-intercalado col-lg-8 col-xl-8 col-md-8 d-flex"
                style="height: 35px"
              >
                <p class="m-0 p-0 pl-2" style="font-size: 1.2rem">
                  Cumplimiento de los objetivos
                </p>
              </div>
              <div
                class="col-12 bg-intercalado d-block pt-1 d-md-none d-lg-none d-xl-none"
              >
                <div
                  class="m-0 p-0 row"
                  style="padding-left: 1.2rem !important"
                >
                  <div class="col-1 p-0 m-0 color-descripcion">1</div>
                  <div class="col-1 p-0 m-0 color-descripcion">2</div>
                  <div class="col-1 p-0 m-0 color-descripcion">3</div>
                  <div class="col-1 p-0 m-0 color-descripcion">4</div>
                  <div class="col-1 p-0 m-0 color-descripcion">5</div>
                </div>
              </div>
              <div class="col-lg-4 col-xl-4 col-md-4 bg-intercalado">
                <div
                  class="align-items-center justify-content-center respuesta m-0 p-0 row"
                  style="padding-top: 0.3rem !important"
                  v-if="calificaciones[0] != undefined"
                >
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline51"
                        name="customRadioInline10"
                        class="custom-control-input"
                        value="1"
                        v-model="calificaciones[posicion].calificacion_11"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline51"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline52"
                        name="customRadioInline10"
                        class="custom-control-input"
                        value="2"
                        v-model="calificaciones[posicion].calificacion_11"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline52"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline53"
                        name="customRadioInline10"
                        class="custom-control-input"
                        value="3"
                        v-model="calificaciones[posicion].calificacion_11"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline53"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline54"
                        name="customRadioInline10"
                        class="custom-control-input"
                        value="4"
                        v-model="calificaciones[posicion].calificacion_11"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline54"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline55"
                        name="customRadioInline10"
                        class="custom-control-input"
                        value="5"
                        v-model="calificaciones[posicion].calificacion_11"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline55"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
              <div
                class="align-items-center col-lg-8 col-xl-8 col-md-8 d-flex"
                style="height: 35px"
              >
                <p class="m-0 p-0 pl-2" style="font-size: 1.2rem">
                  Conocimiento y dominio del tema
                </p>
              </div>
              <div class="col-12 d-block pt-1 d-md-none d-lg-none d-xl-none">
                <div
                  class="m-0 p-0 row"
                  style="padding-left: 1.2rem !important"
                >
                  <div class="col-1 p-0 m-0 color-descripcion">1</div>
                  <div class="col-1 p-0 m-0 color-descripcion">2</div>
                  <div class="col-1 p-0 m-0 color-descripcion">3</div>
                  <div class="col-1 p-0 m-0 color-descripcion">4</div>
                  <div class="col-1 p-0 m-0 color-descripcion">5</div>
                </div>
              </div>
              <div class="col-lg-4 col-xl-4 col-md-4">
                <div
                  class="align-items-center justify-content-center respuesta m-0 p-0 row"
                  style="padding-top: 0.3rem !important"
                  v-if="calificaciones[0] != undefined"
                >
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline56"
                        name="customRadioInline11"
                        class="custom-control-input"
                        value="1"
                        v-model="calificaciones[posicion].calificacion_12"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline56"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline57"
                        name="customRadioInline11"
                        class="custom-control-input"
                        value="2"
                        v-model="calificaciones[posicion].calificacion_12"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline57"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline58"
                        name="customRadioInline11"
                        class="custom-control-input"
                        value="3"
                        v-model="calificaciones[posicion].calificacion_12"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline58"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline59"
                        name="customRadioInline11"
                        class="custom-control-input"
                        value="4"
                        v-model="calificaciones[posicion].calificacion_12"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline59"
                      ></label>
                    </div>
                  </div>
                  <div class="col-1 p-0 m-0" style="height: 20px !important">
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline60"
                        name="customRadioInline11"
                        class="custom-control-input"
                        value="5"
                        v-model="calificaciones[posicion].calificacion_12"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline60"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Ultimo -->
            <div class="row m-0 p-0 mt-3" v-if="calificaciones[0] != undefined">
              <div class="col-lg-8 col-xl-8 col-md-8 col-12">
                <h5 class="color-taller m-0 p-0">
                  Comentarios y sugerencias sobre los temas y conferencistas
                </h5>
              </div>
              <div class="col-lg-4 col-xl-4 col-md-4 d-none d-md-block">
                <h5 class="color-taller text-center m-0 p-0">
                  Recomendaría el taller
                </h5>
              </div>
              <!--  -->
              <div class="col-lg-8 col-xl-8 col-md-8 mt-3">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="calificaciones[posicion].observaciones"
                ></textarea>
              </div>
              <div
                class="col-lg-4 col-xl-4 col-md-4 col-12 d-block d-md-none d-lg-none d-xl-none mt-3"
              >
                <h5 class="color-taller m-0 p-0">Recomendaría el taller</h5>
              </div>
              <div
                class="col-lg-4 col-xl-4 col-md-4 col-12 mt-3 bg-intercalado-col"
              >
                <div
                  class="align-items-center bg-intercalado sin-centrar justify-content-center m-0 mx-5 p-0 py-2 row rounded"
                  style="padding-top: 0.3rem !important"
                >
                  <div
                    class="col-1 col-md-4 col-lg-4 col-xl-4 p-0 m-0"
                    style="height: 20px !important"
                  >
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline61"
                        name="customRadioInline12"
                        class="custom-control-input"
                        value="si"
                        v-model="calificaciones[posicion].calificacion_13"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline61"
                        >Si</label
                      >
                    </div>
                  </div>
                  <div
                    class="col-2 col-md-4 col-lg-4 col-xl-4 p-0 m-0"
                    style="height: 20px !important"
                  >
                    <div
                      class="custom-control custom-radio custom-control-inline m-0"
                    >
                      <input
                        type="radio"
                        id="customRadioInline62"
                        name="customRadioInline12"
                        class="custom-control-input"
                        value="no"
                        v-model="calificaciones[posicion].calificacion_13"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline62"
                        >No</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-xl-8 col-md-12 my-3">
            <div class="row m-0 p-0">
              <div
                class="col-lg-5 col-xl-5 col-md-5 d-flex justify-content-end"
                v-if="falicitadores"
              >
                <button
                  class="btn-atras btn"
                  @click="atras"
                  v-if="posicion > 0"
                >
                  Atras
                </button>
              </div>
              <div
                class="col-lg-1 col-xl-1 col-md-1 col-12 d-flex justify-content-center justify-content-end"
                v-if="falicitadores"
              >
                <h5 class="p-0 mt-2 color-taller">
                  {{ posicion + 1 }}/{{ cantidad_formadores }}
                </h5>
              </div>
              <div
                class="col-lg-5 col-xl-5 col-md-5 col-6 d-flex justify-content-end d-block d-md-none d-lg-none d-xl-none"
                v-if="falicitadores"
              >
                <button
                  class="btn-atras btn"
                  @click="atras"
                  v-if="posicion > 0"
                >
                  Atras
                </button>
              </div>
              <div
                class="col-lg-4 col-xl-4 col-md-4 col-6"
                v-if="falicitadores"
              >
                <button
                  class="btn-evaluacion btn"
                  @click="siguiente"
                  v-if="cantidad_formadores != posicion + 1"
                >
                  Siguiente
                </button>
                <button class="btn-evaluacion btn" v-else @click="guardar">
                  Finalizar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import loading from "@/components/Loading";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import moment from "moment";
export default {
  name: "vocero",
  data() {
    return {
      calificaciones: [],
      posicion: 0,
      cantidad_formadores: 0,
      estado: false,
      foto: process.env.VUE_APP_RUTA_IMG,
      info: [],
    };
  },
  mounted() {
    this.$store.dispatch("fetchNotificador", this.user.user.id);
  },
  components: {
    loading,
  },
  computed: {
    user() {
      return this.$store.getters.getUserAuth;
    },
    falicitadores() {
      return this.$store.getters.getCalificador;
    },
    estadoloading() {
      return this.$store.getters.getEstatusLoading;
    },
  },
  watch: {
    falicitadores(val) {
      this.cantidad_formadores = val ? val.formadores.length : 0;
      for (let index = 0; index < val.formadores.length; index++) {
        const element = val.formadores[index];
        this.calificaciones.push({
          calificacion_1: "",
          calificacion_2: "",
          calificacion_3: "",
          calificacion_4: "",
          calificacion_5: "",
          calificacion_6: "",
          calificacion_7: "",
          calificacion_8: "",
          calificacion_9: "",
          calificacion_10: "",
          calificacion_11: "",
          calificacion_12: "",
          calificacion_13: "",
          observaciones: "",
        });
      }
    },
  },
  methods: {
    atras() {
      this.posicion--;
    },
    siguiente() {
      const isValid = this.validar();
      if (!isValid) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "info",
          title: "Falta un item por calificar.!",
        });
        return false;
      }
      this.info.push({
        not_id: this.falicitadores.not_id,
        formador_id: this.falicitadores.formadores[this.posicion].id,
        taller: this.falicitadores.workshop,
        puntajes: JSON.stringify(this.calificaciones[this.posicion]),
      });
      this.posicion++;
    },
    async guardar() {
      if (this.cantidad_formadores == 1) {
        const valid = this.verificar();
        if (!valid) {
          return false;
        }
      }
      this.info.push({
        not_id: this.falicitadores.not_id,
        formador_id: this.falicitadores.formadores[this.posicion].id,
        taller: this.falicitadores.workshop,
        puntajes: JSON.stringify(this.calificaciones[this.posicion]),
      });
      let datos = {
        registro: JSON.stringify(this.info),
      };
      await this.$store.dispatch("setCalificacion", datos);
      await this.$store.dispatch("refreshUser");
      this.$router.push("/cliente/perfil");
    },
    verificar() {
      const isValid = this.validar();
      if (!isValid) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "info",
          title: "Falta un item por calificar.!",
        });
        return false;
      }
      return true;
    },
    validar() {
      let contador = 0;
      let actual = this.calificaciones[this.posicion];
      if (actual.calificacion_1 == "") {
        contador++;
      }
      if (actual.calificacion_2 == "") {
        contador++;
      }
      if (actual.calificacion_3 == "") {
        contador++;
      }
      if (actual.calificacion_4 == "") {
        contador++;
      }
      if (actual.calificacion_5 == "") {
        contador++;
      }
      if (actual.calificacion_6 == "") {
        contador++;
      }
      if (actual.calificacion_7 == "") {
        contador++;
      }
      if (actual.calificacion_8 == "") {
        contador++;
      }
      if (actual.calificacion_9 == "") {
        contador++;
      }
      if (actual.calificacion_10 == "") {
        contador++;
      }
      if (actual.calificacion_11 == "") {
        contador++;
      }
      if (actual.calificacion_12 == "") {
        contador++;
      }
      if (actual.calificacion_13 == "") {
        contador++;
      }

      if (contador == 0) {
        return true;
      }
      return false;
    },
    format_date(datee) {
      return moment(datee).format("L");
    },
  },
};
</script>
<style lang="css">
.bg-lateral {
  background-color: #f8f8f8;
  height: 100vh;
}

.content-calificacion {
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}

.content-calificacion::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.content-calificacion::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.content-calificacion::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Estilos track de scroll */
.content-calificacion::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.content-calificacion::-webkit-scrollbar-track:hover,
.content-calificacion::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.border-taller {
  border-bottom: 2px solid #7f87b2;
}

.color-taller {
  color: #081f48;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}

.color-descripcion {
  color: #757479;
  font-family: Arial, Helvetica, sans-serif;
}

.border-imagen {
  border: 2px solid #081f48;
  border-radius: 50%;
}

.nombre-formador {
  color: #081f48;
  font-weight: 600;
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
}
.bg-intercalado {
  background-color: #f8f8f8;
}

.btn-evaluacion {
  color: #fff;
  background: rgb(255, 179, 0);
  background: linear-gradient(
    180deg,
    rgba(255, 179, 0, 1) 35%,
    rgba(255, 104, 49, 1) 100%
  );
  outline: none;
  border: none;
}
.btn-evaluacion:hover {
  color: #fff;
  opacity: 0.7;
  background: rgb(255, 179, 0);
  background: linear-gradient(
    180deg,
    rgba(255, 179, 0, 1) 35%,
    rgba(255, 104, 49, 1) 100%
  );
}
.btn.btn-evaluacion:active,
.btn.btn-evaluacion:hover,
.btn.btn-evaluacion:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.btn-atras {
  color: #fff;
  background: rgb(0, 61, 152);
  background: linear-gradient(
    180deg,
    rgba(0, 61, 152, 1) 35%,
    rgba(0, 31, 74, 1) 100%
  );
  outline: none;
  border: none;
}
.btn-atras:hover {
  color: #fff;
  opacity: 0.7;
  background: rgb(0, 61, 152);
  background: linear-gradient(
    180deg,
    rgba(0, 61, 152, 1) 35%,
    rgba(0, 31, 74, 1) 100%
  );
}
.btn.btn-atras:active,
.btn.btn-atras:hover,
.btn.btn-atras:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

/* RESPONSIVE */
@media (min-width: 100px) and (max-width: 448px) {
  .pregunta {
    height: auto !important;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .espaciado {
    margin-top: -1.5rem !important;
  }
  .espaciado div div img {
    width: 160px;
    height: 150px;
  }
  .alineacion-responsive {
    padding-top: 0rem !important;
  }
  .respuesta {
    justify-content: start !important;
    padding-bottom: 1rem !important;
    padding-left: 1rem !important;
  }
  .bg-intercalado-col {
    background-color: #f8f8f8;
  }
  .sin-centrar {
    justify-content: start !important;
    margin: 0 !important;
    padding-left: 1rem !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .espaciado {
    margin-top: 1.5rem !important;
  }
  .espaciado div div img {
    width: 160px;
    height: 150px;
  }
  .alineacion-responsive {
    padding-top: 1rem !important;
  }
}

@media (min-width: 992px) {
  .img-responsive {
    margin-top: 2.3rem !important;
  }
  .alto-personalizado {
    height: auto;
  }
  .alineacion-responsive {
    padding-top: 1rem !important;
  }
}

@media (min-width: 1139px) {
  .img-responsive {
    margin-top: 3rem !important;
  }
}

@media (min-width: 1410px) {
  .alto-personalizado {
    height: 35px;
  }
  .alineacion-responsive {
    padding-top: 0.3rem !important;
  }
}
</style>